import React, {
  useEffect,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

// ** -------------- ACTIONS ------------------------ **
import {
  resetThreeDInfo,
  getAgreementsAction,
  resetInstallmentsAction,
  setInstallmentAction,
  paymenHeaderActiveAction  
} from '@Actions';



// ** ------------- COMPONENTS ---------------------- **
import { Iframe } from '@Components';
import AccordionContainer from '../../CustomAccordion/AccordionContainer';


// ** ----------- THIRD PARTY COMPONENTS ----------- **

// ** ------------- CONFIG ------------------------- **
import config from '@Config';
import { logEvent } from '@Constants';
import { useTitle } from '@Hooks';
import { gettext } from '@Translate';

// ** ------------- ACCORDION ---------------------- **
import 'react-accessible-accordion/dist/fancy-example.css';
import 'tippy.js/dist/tippy.css'; // optional

const PaymentProcess = ({ creditCardRef, isShowBonus }) => {
  const dispatch = useDispatch();

  // ** -------------- GLOBAL STATES ------------------------ **
  const {
    paymentMethods,
    savedCards,
    paymentMethod,
    numberAxess,
    creditCardPlatform,
    creditCartNumber,
    dijitalPaymentMethod,
    addressLoaded,
    dijitalPaymentName,
  } = useSelector((state) => state.order);
  const { shoppingCart } = useSelector((state) => state.cart);
  const { webview } = useSelector((state) => state.config);
  const { paymentheaderIsActive, headerIsActive } = useSelector((state) => state.headerActive);
  const {
    headerValidationWithCard,
    headerValidationWithMonth,
    headerValidationWithCVV,
    headerValidationWithYear,
    isPaymentWithCard,
  } = useSelector((state) => state.cardValidation);
  const { project } = config;

  // ** -------------- LOCAL STATES ------------------------ **
  const [creditCardValid, setCreditCardValidState] = useState(false);
  const [creditCardBlur, setCreditCardBlur] = useState(false);

  useTitle(gettext('Ödeme'));

  const checkPaymentHeader = () => {
    if (headerValidationWithCard && headerValidationWithMonth && headerValidationWithCVV && headerValidationWithYear) {
      setCreditCardValidState(true);
      dispatch(paymenHeaderActiveAction(true));
      setCreditCardBlur(false);
    } else if (headerValidationWithCard || headerValidationWithMonth || headerValidationWithCVV || headerValidationWithYear) {
      setCreditCardValidState(false);
      dispatch(paymenHeaderActiveAction(false));
      setCreditCardBlur(true);
    } else {
      setCreditCardValidState(false);
      dispatch(paymenHeaderActiveAction(false));
      setCreditCardBlur(false);
    }
  };

  // ** --------------------- PAYMENT OPTIONS HEADER VALIDATION ------------------- **
  useEffect(() => {
    if (paymentMethod === 'flopay' && headerValidationWithCard
    && headerValidationWithMonth && headerValidationWithCVV && headerValidationWithYear) {
      if (webview) {
        logEvent('add_payment_info', {
          currency: 'TRY',
          value: shoppingCart.grandTotal,
          items: shoppingCart?.shoppingCartItemList,
          coupon: shoppingCart?.couponData?.couponApplied ? shoppingCart?.couponData?.couponCode : '',
          payment_type: paymentMethod,
        });
      } else {
        if (project !== 'sneakerbox') {
          window.events?.emit('analytics.newPaymentInfo', {});
        }
      }
    }
    checkPaymentHeader();
  }, [
    headerValidationWithCard,
    headerValidationWithMonth,
    headerValidationWithCVV,
    headerValidationWithYear,
  ]);
  // ** --------------------- // ------------------- **
  useEffect(() => {
    return () => {
      dispatch(resetThreeDInfo());
    };
  }, ['init']);

  useEffect(async () => {
    if (addressLoaded === true && paymentMethod && !dijitalPaymentName.includes(paymentMethod)) {
      const floWalletData = paymentMethods.find((item) => item.code === 'flowallet');
      if (
        !(paymentMethod === 'flowallet'
        && floWalletData?.walletShow === false
        && floWalletData?.requireActivation === false)
      ) {
        await dispatch(
          getAgreementsAction({
            method: paymentMethod,
            installment: paymentMethod === 'flowallet' ? 0 : numberAxess,
          }),
        );
        checkPaymentHeader();
      }
    }
  }, [paymentMethod, numberAxess]);

  useEffect(async () => {
    if (addressLoaded === true && dijitalPaymentName.includes(paymentMethod)) {
      await dispatch(
        getAgreementsAction({
          method: dijitalPaymentMethod,
          installment: numberAxess,
        }),
      );
      checkPaymentHeader();
    }
  }, [dijitalPaymentMethod]);

  const messageType = (e) => {
    const data = extractData(e);
  
    if (data) {
      switch (data.messageType || data.type) {
        case 'checkoutAction':
          handleCheckoutAction(data);
          break;
        case 'blockOverlay':
          handleBlockOverlay(data);
          break;
        case 'EDENRED_PAYMENT_FAILED':
          handlePaymentFailure();
          break;
      }
    }
  };
  
  const extractData = (e) => {
    const data = e?.data;
  
    if (typeof data === 'string') {
      try {
        return JSON.parse(data);
      } catch (error) {
        return null;
      }
    } else if (typeof data === 'object' && data !== null) {
      return data;
    }
  
    return null;
  };
  
  const handleCheckoutAction = (data) => {
    try {
      RG.payment.checkoutAction(data);
    } catch (error) {
      console.log('checkoutAction işlenirken hata oluştu:', error);
    }
  };
  
  const handleBlockOverlay = (data) => {
    try {
      RG.Overlay.block(data.message);
    } catch (error) {
      console.log('blockOverlay işlenirken hata oluştu:', error);
    }
  };
  
  const handlePaymentFailure = () => {
    const iframe = document.querySelector('.three-d-secure-popup__iframe');
    if (iframe) {
      setTimeout(() => {
        reloadIframe(iframe);
      }, 3000);
    }
  };
  
  const reloadIframe = (iframe) => {
    try {
      const currentSrc = iframe.src;
      iframe.src = '';
      iframe.src = currentSrc;
    } catch (error) {
      console.log('Iframe yeniden yüklenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageType);

    return () => {
      window.removeEventListener('message', messageType);
    };
  }, ['init']);

  // sayfa yüklendiğinde kayıtlı kart varsa kontrolü

  useEffect(() => {
    if (savedCards && !isPaymentWithCard) {
      if (creditCardPlatform) {
        dispatch(setInstallmentAction({ creditCardPlatform }));
      }
    } else if (creditCartNumber && creditCartNumber?.length > 8) {
      dispatch(setInstallmentAction({ creditCartNumber }));
    } else {
      dispatch(resetInstallmentsAction([]));
    }
  }, [savedCards, creditCardPlatform]);

  return (
    <div className={`delivery-option__container ${project}-delivery-option__container`}>
      <Iframe />
      <div className={`delivery-option__header component-header ${(paymentheaderIsActive && creditCardValid) || !isPaymentWithCard ? 'active-header' : creditCardBlur ? 'orange-header' : ''} ${project}`}>
        {project !== 'reebok' ? <span className="delivery-option__header--step">2</span> : ''}
        <span className={`delivery-option__header-title ${project === 'reebok' ? `${project}-delivery-option__header-title` : ''}`}>{gettext('Ödeme Seçenekleri')}</span>
      </div>
      {/* Teslimat seçenekleri dolu ise Accordion komponentini aktif eder */}
      { headerIsActive ? (
        <AccordionContainer items={paymentMethods} multiple={false} />
      ) : '' }
    </div>
  );
};

export default PaymentProcess;
