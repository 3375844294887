import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  updateCustomerAddressAction,
  getCitiesAction,
  getCountryAction,
  getCountiesAction,
  getNeighbourhoodAction,
  insertCustomerAddressAction,
  setAddressFieldValueAction,
  resetNeighbourhoodAction,
  resetCountysAction,
} from '@Actions';

import {
  validAddress,
  validCorporateInvoice,
  logEvent,
  xmlSpecialControl,
} from '@Constants';
import { useHover } from '@Hooks';
import { gettext } from '@Translate';
import config from '@Config';
import { ValidateEmail, ValidateLuhn } from '@Utils';
import { UnionBlack, UnionWhite, IconWarning } from '@Icons';

import CustomSelect from '../../CustomSelect';
import Input from '../../Elements/Input/Input';
import OTPForm from '../../OTPForm';
import CorporateInvoice from '../CorporateInvoice';
import CustomButton from '../../CustomButton';

const Form = ({
  type, addressType, visibleError, getCitiesPastive, title, warningMessage,
}) => {
  const { project, BASE_URL, phoneCode, isGlobal } = config;
  const [hoverRef, isHovered] = useHover();
  const dispatch = useDispatch();
  const {
    billingAddress,
    shippingAddress,
    countiesArray,
    cities,
    country,
    useShippingAddressAsBillingAddress,
    neighborhoods,
  } = useSelector((state) => state.order);
  const { shoppingCart } = useSelector((state) => state.cart);
  const order = useSelector((state) => state.order);
  const { guest } = useSelector((state) => state.auth);
  const { webview } = useSelector((state) => state.config);
  const [showErrors, setShowErrors] = useState(false);
  const [counties, setCounties] = useState([]);
  const [otpStep, setOtpStep] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [formTitle, setFormTitle] = useState(title);
  const [statusMessage, setStatusMessage] = useState('');
  const [isCorporate, setIsCorporate] = useState(false);
  const [phoneMask, setPhoneMask] = useState('');
  const [phoneCodeDefault, setPhoneCodeDefault] = useState('+90');
  const [addressForm, setaddressForm] = useState({
    addressType: '',
    type: 'address',
    refCode: '',
    time: '',
    phone: '',
    actionType: '',
  });
  const [errMessageFromService, setErrMessageFromService] = useState('');
  const address = order[addressType];

  useEffect(() => {
    if (countiesArray[addressType]) {
      setCounties(countiesArray[addressType]);
    }
  }, [countiesArray, shippingAddress]);

  useEffect(() => {
    (async () => {
      if (type !== 'first') {
        if ((!country || country?.length === 0)) {
          await dispatch(getCountryAction())
        }
        if ((!cities || cities?.length === 0) && !getCitiesPastive) {
          await dispatch(getCitiesAction()).then(async () => {
            if (type === 'edit') {
              if (counties?.length === 0 && address?.cityId) {
                await dispatch(
                  getCountiesAction({
                    cityId: address?.cityId,
                    addressType,
                  }),
                ).then(() => {
                  if ((!neighborhoods || neighborhoods?.length === 0) && address?.countyId && address?.countyId != 0) {
                    dispatch(
                      getNeighbourhoodAction({ districtId: address?.countyId }),
                    );
                  }
                });
              }
            }
          });
        } else if (type === 'edit') {
          if (counties?.length === 0 && address?.cityId) {
            await dispatch(
              getCountiesAction({
                cityId: address?.cityId,
                addressType,
              }),
            ).then(() => {
              if ((!neighborhoods || neighborhoods?.length === 0) && address?.countyId && address?.countyId != 0) {
                dispatch(
                  getNeighbourhoodAction({ districtId: address?.countyId }),
                );
              }
            });
          }
        }
      }
    })();
  }, [cities]);

  useEffect(() => {
    setShowErrors(visibleError);
  }, [visibleError]);

  useEffect(() => {
    otpStep ? setFormTitle('Telefon Numaranızı Doğrulayın') : setFormTitle(title);
  }, [otpStep]);

  const handleOnChangeCountry = async (event) => {
    const selectedCountry = country.filter((item) => item.local_name === event.local_name);
    dispatch(
      setAddressFieldValueAction({ addressType, key: 'countryId', value: selectedCountry[0]?.local_name }),
    );
    setTimeout(() => {
      dispatch(
        setAddressFieldValueAction({ addressType, key: 'cityName', value: '' }),
      );
      dispatch(
        setAddressFieldValueAction({ addressType, key: 'cityId', value: '' }),
      );
      dispatch(
        setAddressFieldValueAction({ addressType, key: 'countyName', value: '' }),
      );
    }, 0);
    await dispatch(
      getCitiesAction({ countryId: event.local_name, addressType }),
    );
  };

  const handleOnChangeCity = async (event) => {
    await dispatch(resetCountysAction());
    await dispatch(resetNeighbourhoodAction());
    const selectedCity = cities?.filter(
      (item) => item?.cityId === event?.cityId,
    );
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'cityName',
        value: selectedCity[0]?.name,
      }),
    );
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'cityId',
        value: selectedCity[0]?.cityId,
      }),
    );

    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'countyName',
        value: '',
      }),
    );

    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'neighborhoodName',
        value: '',
      }),
    );
    await dispatch(
      getCountiesAction({ cityId: event?.cityId, addressType }),
    );
  };

  const handleOnChangeCounty = async (event) => {
    await dispatch(resetNeighbourhoodAction());
    const selectedCity = counties?.filter(
      (item) => item?.name?.trim() === event?.name?.trim(),
    );
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'countyName',
        value: selectedCity[0]?.name,
      }),
    );
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'neighborhoodName',
        value: '',
      }),
    );
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'countyId',
        value: selectedCity[0]?.districtId,
      }),
    );
    await dispatch(
      getNeighbourhoodAction({ districtId: selectedCity[0]?.districtId }),
    );
  };

  const handleOnChangeNeighborhoods = async (event) => {
    const selectedNeighbordhood = neighborhoods?.filter(
      (item) => item?.name?.trim() === event?.name?.trim(),
    );
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'neighborhoodName',
        value: selectedNeighbordhood[0]?.name,
      }),
    );

    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'neighborhoodId',
        value: selectedNeighbordhood[0]?.id,
      }),
    );
  };

  const handleOnChangePhoneCode = async (event) => {
    setPhoneMask(event?.mask);
    const selectedPhoneCode = phoneCode?.filter(
      (item) => item?.name?.trim() === event?.name?.trim(),
    );
    setPhoneCodeDefault(event?.name);
    dispatch(
      setAddressFieldValueAction({
        addressType,
        key: 'phonecode',
        value: selectedPhoneCode[0]?.name,
      }),
    );
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    let emailValid = true;
    if (guest) {
      emailValid = ValidateEmail(address?.email);
    }
    const luhnCheck = ValidateLuhn([address?.titleAddress, address?.address]);
    const nameValidation = address?.firstName.length >= 3 && address?.lastName.length >= 3
    const isNeighborhoodActive = neighborhoods && neighborhoods?.length > 0;
    if (luhnCheck) {
      setIsSuccess(false);
      setStatusMessage('Lütfen adres bilgilerinizi kontrol ediniz.');
    } else if (validAddress(address, guest, isNeighborhoodActive, phoneCodeDefault) && validCorporateInvoice(address, isCorporate) && emailValid && nameValidation) {
      if (type === 'edit') {
        await dispatch(
          updateCustomerAddressAction({
            shippingAddress:
              addressType === 'shippingAddress'
                ? shippingAddress
                : billingAddress,
            action: 'update',
            hidePopup: false,
            isCorporate,
            addressType,
          }),
        ).then(({ payload }) => {
          const {
            showVerifiedCode, phone, refCode, time, success, message, phonecode, status,
          } = payload;
          if (status?.code && status?.code !== 200) {
            setErrMessageFromService(status.message);
          } else {
            setErrMessageFromService('');
          }
          if (showVerifiedCode === true) {
            setaddressForm({
              actionType: type,
              addressType,
              phone,
              phonecode,
              refCode,
              time,
              type: 'address',
            });
            setOtpStep(true);
          }
          if (success === false) {
            setIsSuccess(false);
            setStatusMessage(message);
          } else {
            setIsSuccess(true);
            setStatusMessage('');
          }
        });
      } else if (type === 'new') {
        await dispatch(insertCustomerAddressAction({
          shippingAddress: addressType === 'shippingAddress' ? shippingAddress : billingAddress,
          action: 'insert',
          sameAddress: useShippingAddressAsBillingAddress,
          addressType,
          hidePopup: false,
        })).then(({ payload }) => {
          const {
            showVerifiedCode, phone, refCode, time, success, message, phonecode, status,
          } = payload;
          if (status?.code && status?.code !== 200) {
            setErrMessageFromService(status.message);
          } else {
            setErrMessageFromService('');
          }
          if (showVerifiedCode === true) {
            setaddressForm({
              actionType: type,
              addressType,
              phone,
              phonecode,
              refCode,
              time,
              type: 'address',
            });
            setOtpStep(true);
          }
          if (success === false) {
            setIsSuccess(false);
            setStatusMessage(message);
          } else {
            setIsSuccess(true);
            setStatusMessage('');
            // yeni adres ekleme işlerinde tetiklenmesi gereken eventler
            if (webview) {
              logEvent('add_shipping_info', {
                currency: 'TRY',
                value: shoppingCart?.grandTotal,
                coupon: shoppingCart?.couponData?.couponApplied ? shoppingCart?.couponData?.couponCode : '',
                items: shoppingCart?.shoppingCartItemList,
              });
            }
            if (!webview && project !== 'sneakerbox') {
              window.events?.emit('analytics.newShippingInfo', {});
            }
          }
        });
      }
    } else {
      setShowErrors(true);
    }
  };

  useEffect(() => {
    if (address?.corporateAddress) {
      setIsCorporate(true);
    }
    if (phoneCode?.length > 0) {
      let phoneCodeEdit = null;
      if (address?.phonecode) {
        phoneCodeEdit = phoneCode.filter((item) => item.name === address?.phonecode);
      }
      if (phoneCodeEdit?.length > 0) {
        setPhoneMask(phoneCodeEdit[0]?.mask);
        setPhoneCodeDefault(phoneCodeEdit[0]?.name);
        dispatch(
          setAddressFieldValueAction({
            addressType,
            key: 'phonecode',
            value: phoneCodeEdit[0]?.name,
          }),
        );
      } else {
        setPhoneMask(phoneCode[0]?.mask);
        setPhoneCodeDefault(phoneCode[0]?.name);
        dispatch(
          setAddressFieldValueAction({
            addressType,
            key: 'phonecode',
            value: phoneCode[0]?.name,
          }),
        );
      }
    } else {
      dispatch(
        setAddressFieldValueAction({
          addressType,
          key: 'phonecode',
          value: '+90',
        }),
      );
    }
  }, []);

  // Login ve register da login buttonları farklı noktalara gideceği için bu fonksiyon prop olarak alınacak
  const FormOTPBackButton = () => {
    dispatch(
      setAddressFieldValueAction({
        addressType: addressForm?.addressType,
        key: 'otp',
        value: '',
      }),
    );
    setOtpStep(false);
  };

  return (
    <div>
      <form
        className="form-address-edit new-shipping-address"
        method="POST"
        id="address-edit-form"
        encType="multipart/form-data"
      >
        <div
          data-widget="address/form"
          className="widget widget_address_form"
        >
          <div className="checkout-address__form">
            <input
              name="form_key"
              type="hidden"
              defaultValue="l4EFzCZObjNl9Kk4"
              id="adressss"
            />
            <input type="hidden" name="error_url" defaultValue="" />
            <input
              type="hidden"
              name="address_id"
              defaultValue="111010"
            />
            <input
              name="country_id"
              id="country"
              type="hidden"
              title="Country"
              aria-required="true"
              defaultValue="TR"
            />
            <input
              type="hidden"
              name="default_shipping"
              defaultValue="false"
              title="default shipping"
              id="default_shipping"
            />
            <input
              type="hidden"
              name="default_billing"
              defaultValue="false"
              title="billing"
              id="default_billing"
            />
            <input
              type="hidden"
              data-name="csrf_token"
              name="csrf_token"
              id="csrf_token"
              defaultValue="ca1a93f435127ddd07d8b3978b96e54a5860e17a"
            />

            {formTitle && (
              <h1 className={`checkout-address__heading component-header ${project}-checkout-address__heading mb-0`}>
                {gettext(formTitle)}
              </h1>
            )}

            {errMessageFromService !== '' && !warningMessage && !otpStep && (
              <div className="checkout-address__warning checkout-address__warning--service">
                <span className="checkout-address__warning-desc">{gettext(errMessageFromService)}</span>
              </div>
            )}

            {warningMessage && !isGlobal && !otpStep && (
              <div className={`checkout-address__warning ${project} light-bg`}>
                <IconWarning />
                <span className="checkout-address__warning-desc">{gettext('Daha kolay teslimat için mahalle bilginizi giriniz.')}</span>
              </div>
            )}

            { !isSuccess && (
              <div className="auth__form-alert">
                {' '}
                {statusMessage}
                {' '}
              </div>
            )}

            <div className="checkout-address__form-wrapper">
              {otpStep && (
                <OTPForm
                  formDetail={addressForm}
                  onBackButtonClick={FormOTPBackButton}
                  isBackActive={false}
                />
              )}
              {!otpStep && (
                <>
                  {guest && (
                  <div className="form-group ">
                    <Input
                      error={showErrors}
                      type="text"
                      name="email"
                      data-rule-required="true"
                      errorMessage={gettext(
                        'Lütfen geçerli bir mail adresi giriniz.',
                      )}
                      className={`form-control checkout-address__input ${project}-checkout-address__input`}
                      value={address?.email || ''}
                      pattern="email"
                      dataTest="checkout-address-default-email-change"
                      onChange={(event) => {
                        dispatch(
                          setAddressFieldValueAction({
                            addressType,
                            key: 'email',
                            value: event.target.value,
                          }),
                        );
                      }}
                      autoComplete="off"
                      placeholder={gettext('E-Posta Adresiniz')}
                      tabIndex='1'
                    />
                  </div>
                  )}
                  {!guest && (
                  <div className="form-group active">
                    <Input
                      error={showErrors}
                      name="title_address"
                      placeholder={gettext('Adres Başlığı')}
                      maxlength={11}
                      id="title_address"
                      pattern="alphanumeric"
                      minLength={2}
                      value={address?.titleAddress || ''}
                      dataTest="checkout-address-default-title-address-change"
                      onChange={(event) => {
                        dispatch(
                          setAddressFieldValueAction({
                            addressType,
                            key: 'titleAddress',
                            value: event.target.value?.replace('_', ''),
                          }),
                        );
                      }}
                      autoComplete="off"
                      type="text"
                      className={`form-control checkout-address__input ${project}-checkout-address__input`}
                      tabIndex='2'
                    />
                  </div>
                  )}
                  <div className="small-gutter checkout-address__name-wrapper">
                    <div className="form-group active">
                      <div>
                        <Input
                          error={showErrors}
                          errorMessage={gettext('Lütfen en az 3 karakter uzunluğunda bir değer giriniz.')}
                          placeholder={gettext('Ad')}
                          name="firstName"
                          minLength={3}
                          pattern="onlyAlphabets"
                          value={address?.firstName || ''}
                          dataTest="checkout-address-default-firstname-change"
                          onChange={(event) => {
                            dispatch(
                              setAddressFieldValueAction({
                                addressType,
                                key: 'firstName',
                                value: event.target.value?.replace(
                                  '_',
                                  '',
                                ),
                              }),
                            );
                          }}
                          maxLength="50"
                          autoComplete="off"
                          type="text"
                          className={`form-control checkout-address__input ${project}-checkout-address__input`}
                          tabIndex='3'
                          />
                      </div>
                      <div>
                        <Input
                          error={showErrors}
                          placeholder={gettext('Soyad')}
                          errorMessage={gettext('Lütfen en az 3 karakter uzunluğunda bir değer giriniz.')}
                          name="lastName"
                          minLength={3}
                          pattern="onlyAlphabets"
                          value={address?.lastName || ''}
                          dataTest="checkout-address-default-lastname-change"
                          onChange={(event) => {
                            dispatch(
                              setAddressFieldValueAction({
                                addressType,
                                key: 'lastName',
                                value: event.target.value?.replace(
                                  '_',
                                  '',
                                ),
                              }),
                            );
                          }}
                          maxLength="50"
                          autoComplete="off"
                          type="text"
                          className={`form-control checkout-address__input ${project}-checkout-address__input`}
                          tabIndex='4'
                          />
                      </div>
                    </div>
                  </div>
                  <div className="select-wrapper form-group active">
                    { (phoneCode?.length > 1 || (phoneCode?.length === 1 && phoneCodeDefault !== '+90')) && (
                      <div className="select-group select-group--phone">
                        <CustomSelect
                          list={phoneCode}
                          name="phonecode"
                          type="default"
                          defaultValue={address?.phonecode || ''}
                          onChange={handleOnChangePhoneCode}
                          dataTest="checkout-address-default-phone-code-change"
                          tabIndex="5"
                        />
                      </div>
                    )}
                    <div className="select-group">
                      <Input
                        error={showErrors}
                        placeholder={gettext('Cep Telefonu')}
                        type="tel"
                        secondMessage={gettext(
                          'Lütfen geçerli bir numara giriniz.',
                        )}
                        name="phone"
                        pattern="phone"
                        mask={phoneMask}
                        phoneCodeDefault={phoneCodeDefault}
                        value={address?.phone || ''}
                        dataTest="checkout-address-default-phone-change"
                        onChange={(event) => {
                          dispatch(
                            setAddressFieldValueAction({
                              addressType,
                              key: 'phone',
                              value: event.target.value,
                            }),
                          );
                        }}
                        className={`form-control checkout-address__input ${project}-checkout-address__input`}
                        tabIndex='6'
                      />
                    </div>
                  </div>
                  <div className={project === 'lumberjack_it' ? "select-wrapper select-wrapper--triple form-group" : "select-wrapper form-group"}>
                    {project === 'lumberjack_it' && (
                      <div className="select-group">
                        <CustomSelect
                          list={country}
                          title={gettext('Ülke')}
                          filter="true"
                          name="country"
                          type="default"
                          defaultValue={address?.countryId?.trim() || ''}
                          onChange={handleOnChangeCountry}
                          dataTest="checkout-address-default-country-change"
                          tabIndex="7"
                        />
                        {showErrors && address && !address.countryId && (
                          <div className="error">
                            {gettext('Lütfen bu alanı doldurunuz')}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="select-group">
                      <CustomSelect
                        list={cities}
                        title={gettext('İl')}
                        filter="true"
                        name="city"
                        type="default"
                        defaultValue={address?.cityName?.trim() || ''}
                        onChange={handleOnChangeCity}
                        dataTest="checkout-address-default-city-change"
                        tabIndex="8"
                      />
                      {showErrors && address && !address.cityId && (
                        <div className="error">
                          {gettext('Lütfen bu alanı doldurunuz')}
                        </div>
                      )}
                    </div>
                    <div className="select-group">
                      <CustomSelect
                        list={counties}
                        title={gettext('İlçe')}
                        name="county"
                        type="default"
                        defaultValue={address?.countyName?.trim() || ''}
                        filter="true"
                        onChange={handleOnChangeCounty}
                        dataTest="checkout-address-default-county-change"
                        tabIndex="9"
                      />
                      {showErrors && address && !address?.countyName && (
                        <div className="error">
                          {gettext('Lütfen bu alanı doldurunuz')}
                        </div>
                      )}
                    </div>
                    <input
                      id="region_id_"
                      type="hidden"
                      name="region_id"
                      value="1518"
                    />
                  </div>
                  {!isGlobal && (
                    <div className="form-group">
                      <div className="select-group">
                        {((neighborhoods && neighborhoods?.length > 0)) && (
                          <>
                            <CustomSelect
                              list={neighborhoods}
                              title={gettext('Mahalle')}
                              name="neighborhoods"
                              type="default"
                              defaultValue={address?.neighborhoodName?.trim() || ''}
                              filter="true"
                              onChange={handleOnChangeNeighborhoods}
                              isFocused={warningMessage}
                              dataTest="checkout-address-default-neighborhoods-change"
                              tabIndex="10"
                            />
                            {showErrors && address && !address?.neighborhoodName && (
                            <div className="error">
                              {gettext('Lütfen bu alanı doldurunuz')}
                            </div>
                            )}
                          </>
                        )}

                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <textarea
                      name="street"
                      value={address?.address || ''}
                      data-test="checkout-address-default-street-change"
                      onChange={(event) => {
                        dispatch(
                          setAddressFieldValueAction({
                            addressType,
                            key: 'address',
                            value: event.target.value,
                          }),
                        );
                      }}
                      title="Address"
                      autoComplete="off"
                      className={`form-control checkout-address__adress-area ${project}-checkout-address__adress-area`}
                      placeholder={gettext(
                        'Adres',
                      )}
                      maxLength="200"
                      rows="5"
                      tabIndex="11"
                    />
                    {showErrors
                    && (!address?.address
                      || address?.address?.length <= 10 || !xmlSpecialControl(address?.address)) && (
                      <div className="error">
                        {gettext('Adres alanına 10 karakterden fazla ve uygun karakterler giriniz.')}
                      </div>
                    )}
                  </div>
                  {project === 'lumberjack_it' && (
                    <div className="form-group active">
                      <Input
                        error={showErrors}
                        name="postal_code"
                        placeholder={gettext('Posta Kodu')}
                        maxlength={20}
                        id="postal_code"
                        pattern="alphanumeric"
                        value={address?.postalCode || ''}
                        dataTest="checkout-address-default-postal-code"
                        onChange={(event) => {
                          dispatch(
                            setAddressFieldValueAction({
                              addressType,
                              key: 'postalCode',
                              value: event.target.value?.trim(),
                            }),
                          );
                        }}
                        autoComplete="off"
                        type="text"
                        className={`form-control checkout-address__input ${project}-checkout-address__input`}
                        tabIndex='12'
                      />
                    </div>
                  )}
                  {project !== 'sneakerbox' && (
                    <CorporateInvoice addressType={addressType} isCorporate={isCorporate} onIsCorporateChange={myIsCorporate => setIsCorporate(myIsCorporate)} showErrors={showErrors} />
                  )}

                  {type !== 'first' && (
                  <div className="mt-3" data-test="checkout-adress-form-custom-button">
                    {
                      project === 'reebok' ? (
                      <button
                        className="reebok-payment-step-button reebok-adress-button"
                        onClick={handleSubmit}
                        data-test="reebok-checkout-summary-complete-action-button"
                      >
                        <div
                          ref={hoverRef}
                          className="reebok-payment-step-button-text-wrapper"
                        >
                          <span className="reebok-payment-step-button__text">
                          {gettext('Adresi Kaydet')}
                          </span>
                          <span className="">
                            {isHovered ? <UnionBlack /> : <UnionWhite />}
                          </span>
                        </div>
                      </button> )
                      :
                        (<CustomButton
                        type="button"
                        className={`checkout-address__form-save-button ${project}-checkout-address__form-save-button`}
                        dataTest="save-to-checkout-address-new-address-modal-shipping_address"
                        data-action="save-address"
                        title="KAYDET"
                        onClick={handleSubmit}
                      >
                        {gettext('Adresi Kaydet')}
                      </CustomButton>)
                    }
                    {guest && (
                    <div className="auth__form-helper text-dark mt-3">
                      Kişisel verileriniz,
                      {' '}
                      <a
                        href={`${BASE_URL}aydinlatma-metni`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <u>{gettext('Flo Müşteri Aydınlatma Metni')}</u>
                      </a>
                      {' '}
                      {gettext('uyarınca işlenecektir.')}
                    </div>
                    )}
                  </div>
                  )}
                  {/* wrapper end */}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

Form.defaultProps = {
  title: '',
  addressType: '',
  visibleError: false,
  getCitiesPastive: false,
  type: '',
  warningMessage: false,
};

Form.propTypes = {
  type: PropTypes.string.isRequired,
  visibleError: PropTypes.bool.isRequired,
  getCitiesPastive: PropTypes.bool.isRequired,
  title: PropTypes.string,
  addressType: PropTypes.string,
  warningMessage: PropTypes.bool,
};

export default Form;
