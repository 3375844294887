import React from 'react';
import { gettext } from '@Translate';
import { applyBonusAction } from '@Actions';
import { useDispatch, useSelector } from 'react-redux';
import { InstreetClubIcon, TierOne, TierTwo, TierThree } from '@Icons';
import { toMoneyBonus } from '@Constants';

const InstreetClub = () => {
  const { shoppingCart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleLoyaltyCheck = (e) => {
    const isApplied = e.target.checked;
    if (typeof isApplied === 'boolean') {
      dispatch(applyBonusAction({ isApplied }));
    }
  };

  const renderLoyaltyTierIcon = () => {
    const tierAlias = shoppingCart?.loyalty360Data?.tierAlias;
    switch (tierAlias) {
      case 'tier1':
        return <TierOne />;
      case 'tier2':
        return <TierTwo />;
      case 'tier3':
        return <TierThree />;
      default:
        return null;
    }
  };

  return (
    shoppingCart?.loyalty360Data?.bonus > 0 && (
      <div className="club-box-wrapper">
      <div className="club-box-container">
        <ClubTitle />
        <ClubBody
          tierName={shoppingCart?.loyalty360Data?.tierName}
          renderLoyaltyTierIcon={renderLoyaltyTierIcon}
        />
        <ClubFooter
          isApplied={shoppingCart?.loyalty360Data?.isApplied}
          bonus={shoppingCart?.loyalty360Data?.bonus}
          handleLoyaltyCheck={handleLoyaltyCheck}
        />
      </div>
    </div>
  ));
};

const ClubTitle = () => (
  <div className="club-box-container__title">
    <div className="club-box-container__title--icon">
      <InstreetClubIcon />
    </div>
  </div>
);

const ClubBody = ({ tierName, renderLoyaltyTierIcon }) => (
  <div className="club-box-container__body">
    <div className="club-box-container__body--icons">{renderLoyaltyTierIcon()}</div>
    <div>
      <p className="club-box-container__body--tier">{tierName}</p>
      <p className="club-box-container__body--description">
        {gettext('Programı kapsamında kullanabileceğiniz')} <strong>{gettext('Club Para')}</strong>{gettext('’larınız var.')}
      </p>
    </div>
  </div>
);

const ClubFooter = ({ isApplied, bonus, handleLoyaltyCheck }) => (
  <div className={`club-box-container__footer ${isApplied ? 'applied' : ''}`}>
    <label htmlFor="club-point-using" className="checkbox checkbox--gray">
      <input
        type="checkbox"
        id="club-point-using"
        checked={isApplied}
        onChange={handleLoyaltyCheck}
      />
      <span />
      <div>
        <div className="club-box-container__footer--title">{gettext('Club Para')}</div>
        <p className="club-box-container__footer--desc">
          <strong>{toMoneyBonus(bonus)}</strong> {gettext('club para puanı hemen kullan.')}
        </p>
      </div>
    </label>
  </div>
);

export default InstreetClub;
