import React, { lazy } from 'react';

import Loader from './Loader/Loader';
import ToRedirect from './ToRedirect/ToRedirect';
import RequireAuth from './RequireAuth/RequireAuth';
import RedirectToPage from './RedirectToPage/RedirectToPage';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import ToastfyMessage from './ToastfyMessage/ToastfyMessage';
import ToastfySuccess from './ToastfySuccess/ToastfySuccess';
import ToastfyConfirm from './ToastfyConfirm/ToastfyConfirm';
import ToastfyCouponConfirm from './ToastfyCouponConfirm/ToastfyCouponConfirm';
import ToastifyDisaster from './ToastifyDisaster';
import Logo from './Logo/Logo';
import MetaTags from './MetaTags';
const ReebokProductCard = lazy(() => import('./ReebokProductCard'));
const RequireWebView = lazy(() => import('.//RequireWebView'));
// TODO: Burak Özkan Lazy durumda function success sayfasında undefined dönüyor ve kayıtsız kart ile ilerlendiğinde sayfa hata veriyor bu alan kontrol edilecek.
import SaveCardPopup from './SaveCardPopup/index';
const InfoBox = lazy(() => import('./InfoBox'));
const InfoBoxWithBtn = lazy(() => import('./InfoBoxWithBtn'));

// Basket Components
const GalleryModal = lazy(() => import('./Basket/GalleryModal'));
const Gallery = lazy(() => import('./Basket/Gallery'));
import SizeWrapper from './Basket/SizeWrapper';

const OldProducts = lazy(() => import('./Basket/OldProducts'));

const ShareBasket = lazy(() =>
  import('./Basket/ShareBasket/ShareBasket')
);

// Adress Components
import Form from './Adress/DeliveryOptionDefaultForm'
const CorporateInvoice = lazy(() => import('./Adress/CorporateInvoice'));
const AddressOptions = lazy(() => import('./Adress/AddressOptions'));
const ClickAndCollect = lazy(() => import('./Adress/ClickAndCollect'));
const Information = lazy(() => import('./Adress/Information'));
const DeliveryOptions = lazy(() => import('./Adress/DeliveryOptions'));
const DeliveryOptionCC = lazy(() => import('./Adress/DeliveryOptionCC'));
const DeliveryOptionDefault = lazy(() => import('./Adress/DeliveryOptionDefault'));
const StoresMap = lazy(() => import('./Adress/StoresMap'));


import Typography from './Typography';

// ** -------------- PAYMENT PROCESS -------------------------- **
import { SmsActivation } from './PaymentOptions';

import CustomBadge from './CustomBadge';

// CustomButton
import CustomButton from './CustomButton';

// CustomSelect
import CustomSelect from './CustomSelect';

// Custom Banner
import CustomBanner from './CustomBanner';

// Custom Popover
import CustomPopover from './CustomPopover';

// Discount Coupons
import DiscountCoupons from './DiscountCoupons'

// Merchant Products Card
import MerchantProductCard from './MerchantProductCard';

// ProductDetailCard
import ProductDetail from './ProductDetail';

import LoyaltyBonus from './LoyaltyBonus';

import RenderSwitch from './RenderSwitch';

import OrderSummary from './OrderSummary';

// CustomIframe
import CustomIframe from './CustomIframe';

// Product Card
import ProductCard from './ProductCard';

import Agreements from './Agreements';

import Navbar from './Navbar/index';
import Sidebar from './Sidebar/index';
import Popup from './Popup/index';
import Input from './Elements/Input/Input';



// Bags Card Wrapper
const Bags = lazy(() => import('./Bags'));
// Bag Card
const BagCard = lazy(() => import('./BagCard'));
// Bag Card
const BagPopup = lazy(() =>import('./BagPopup'));
//

// Saved Card Components
import {
  SavedCardList,
  SavedCard,
} from './SavedCardWithoutShopping';

//Barcode Scanner Components
import BarcodeScanner from './BarcodeScanner';

//  Cargo Free With Accessories
const CargoFreeWithAccessories = lazy(() => import('./CargoFreeWithAccessories'));
const CurrencyInput = lazy(() => import('./Elements/CurrencyInput/CurrencyInput'));
const Loading = lazy(() => import('./Loading/Loading'));
const OutOfStock = lazy(() => import('./OutOfStock'));
const OutOfStockCard = lazy(() => import('./OutOfStockCard'));
const OTPForm = lazy(() => import('./OTPForm'));
const OTPInput = lazy(() => import('./OTPInput'));

const Coupon = lazy(() => import('./Sidebar/Coupon'));
const AccordionContainer = lazy(() =>
  import('./CustomAccordion/AccordionContainer')
);

const DefaultInfo = lazy(() =>
  import('./PaymentOptions/DefaultInfo')
);
const CreditCard = lazy(() =>
  import('./PaymentOptions/PaymentOptionContents/CreditCard')
);
const CashOnDelivery = lazy(() =>
  import('./PaymentOptions/PaymentOptionContents/CashOnDelivery')
);
const DijitalPayment = lazy(() =>
  import('./PaymentOptions/PaymentOptionContents/DijitalPayment')
);
// const SmsActivation = lazy(() => import('./PaymentOptions/SMSActivation'));
const WalletOtp = lazy(() => import('./PaymentOptions/WalletOtp'));
const PaymentProcess = lazy(() =>
  import('./PaymentOptions/PaymentProcess')
);
const Iframe = lazy(() => import('./PaymentOptions/Iframe'));

//Saved card Without Shopping
const NonSavedCard = lazy(() => import('./SavedCardWithoutShopping/NonSavedCard'));

const LoyaltyProgram = lazy(() => import('./LoyaltyProgram'));

export {
  ErrorBoundary,
  ToastfyMessage,
  ToastfySuccess,
  ToastfyConfirm,
  SaveCardPopup,
  ToastfyCouponConfirm,
  ToRedirect,
  RequireAuth,
  RedirectToPage,
  Loader,
  OrderSummary,
  Agreements,
  Coupon,
  Navbar,
  Loading,
  Sidebar,
  Popup,
  Input,
  CurrencyInput,
  Logo,
  OutOfStock,
  OutOfStockCard,
  MetaTags,
  LoyaltyProgram,
  OTPForm,
  CargoFreeWithAccessories,
  ProductCard,
  DiscountCoupons,
  MerchantProductCard,
  CustomButton,
  RenderSwitch,
  LoyaltyBonus,
  Iframe,
  GalleryModal,
  OldProducts,
  ShareBasket,
  Gallery,
  SizeWrapper,
  CustomBadge,
  ProductDetail,
  DeliveryOptions,
  Information,
  AddressOptions,
  CorporateInvoice,
  ClickAndCollect,
  Form,
  DeliveryOptionDefault,
  DeliveryOptionCC,
  StoresMap,
  PaymentProcess,
  DefaultInfo,
  CreditCard,
  CashOnDelivery,
  DijitalPayment,
  SmsActivation,
  WalletOtp,
  CustomSelect,
  CustomBanner,
  CustomIframe,
  AccordionContainer,
  BagPopup,
  Bags,
  BagCard,
  RequireWebView,
  ToastifyDisaster,
  ReebokProductCard,
  Typography,
  NonSavedCard,
  SavedCardList,
  SavedCard,
  CustomPopover,
  InfoBox,
  InfoBoxWithBtn,
  BarcodeScanner,
  OTPInput
};
