import React from 'react';
import moment from 'moment';
import config from '@Config';
import PropTypes from 'prop-types';
import { gettext } from '@Translate';
import { toMoney } from '@Constants';

moment.locale('tr');

const Items = ({
  name, code, toDate, isActive, onSubmit, conditionMinimumAmount,
}) => {
  const { project } = config;
  return (
    <div className={`${project}-coupon-item ${project}-coupon-item${isActive ? '--active' : ''} coupon-item${isActive ? '--active' : ''}`} key={code}>
      <div className="coupon-item__left d-flex flex-column justify-content-between">
        <div className="coupon-item__left--strong" data-title={name}>
          {name || code}
        </div>
        <div className="coupon-item__left-date">
          {gettext('Son Kullanma Tarihi:')}{' '}
          <div>{toDate}</div>
        </div>
      </div>
      <div className="coupon-item__right justify-content-between">
        <button
          data-test="click-to-cart-page-choose-coupon"
          id={isActive ? 'remove-coupon-btn' : 'apply-coupon-btn'}
          type="button"
          className={`coupon-item__right-button ${project}-coupon-item__right-button ${isActive ? 'remove-btn' : ''} btn ${project}`}
          onClick={() => onSubmit(code, isActive)}
        >
          {isActive ? gettext('Kaldır') : gettext('Kullan')}
        </button>

        {conditionMinimumAmount > 0 && (
        <div className="coupon-item__right-limit">
          <span>{gettext('Alt Limit:')}</span>
          {project === 'sneakerbox' || project === 'flo-kz' || project === 'lumberjack_it' ? (
            <span>{toMoney(conditionMinimumAmount)}</span>
          ) : (
            <span>{conditionMinimumAmount} TL</span>
          )}
        </div>
        )}

      </div>
    </div>
  );
};

Items.defaultProps = {
  name: '',
  toDate: '',
  isActive: false,
  conditionMinimumAmount: 0,
};

Items.propTypes = {
  name: PropTypes.string,
  toDate: PropTypes.string,
  isActive: PropTypes.bool,
  conditionMinimumAmount: PropTypes.number,
  code: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Items;
