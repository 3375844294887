import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
} from '@Icons';
import OTPInput from '../OTPInput';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router';
import {
  updateCustomerAddressAction,
  insertCustomerAddressAction,
  setAddressFieldValueAction,
  registerAction,
  phoneLoginAction,
  isPhoneLoginAction,
  hidePopupAction,
  setIsMailVerifiedAction,
} from '@Actions';
import config from '@Config';
import { gettext } from '@Translate';
import { toast } from 'react-toastify';
import { ToastfySuccess } from '@Components';
import APIs from '@API';
import { goToLogin } from '@Utils';
import IconTime from '../../assets/icons/time-icon.svg';
import IconTimeBlack from '../../assets/icons/time-icon-black.svg';
import IconTimePurple from '../../assets/icons/time-icon-purple.svg';

const OTPForm = ({
  formDetail,
  onBackButtonClick,
  isBackActive,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    billingAddress,
    shippingAddress,
    useShippingAddressAsBillingAddress,
  } = useSelector((state) => state.order);
  const startDate = useRef(Date.now());
  const [OTP, setOTP] = useState('');
  const [phone, setPhone] = useState('');
  const [refCode, setRefcode] = useState('');
  const [formType, setFormType] = useState('');
  const [addressForm, setAddressForm] = useState({});
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [reSendCode, setReSendCode] = useState(true);
  const [reCodeCount, setReCodeCount] = useState(0);
  const [time, setTime] = useState('');
  const [timeReset, setTimeReset] = useState(true);

  const { project, BASE_URL, CHECKOUT_BASE_URL } = config;

  const renderer = ({ completed, formatted }) => {
    if (completed) {
      setReSendCode(false);

      // kodu tekrar gönderince sürenin yenilenmemesinden dolayı eklendi
      setTimeReset(true);

      return (
        <span>
          00:00
        </span>
      );
    }
    return (
      <span>
        {formatted?.minutes}
        :
        {formatted?.seconds}
      </span>
    );
  };

  const insertCustomerAddress = async (data) => {
    await dispatch(insertCustomerAddressAction(data)).then(({ payload }) => {
      const {
        status, message, time, refCode, showVerifiedCode,
      } = payload;
      if (status?.code && status?.code !== 200) {
        setErrMessage(status?.message);
      } else if (status === false) {
        setErrMessage(message);
      }
      if (showVerifiedCode && status === true) {
        setErrMessage('Lütfen telefonunuza gelen doğrulama kodunu giriniz.');
        startDate.current = Date.now();
        setTime(time * 1000);
        setRefcode(refCode);
        setReSendCode(true);
      }
      setOTP('');
      dispatch(
        setAddressFieldValueAction({
          addressType: addressForm.addressType,
          key: 'otp',
          value: '',
        })
      );
    }
    );
  };

  const updateCustomerAddress = async (data) => {
    await dispatch(updateCustomerAddressAction(data)).then(({ payload }) => {
      const {
        status, message, time, refCode, showVerifiedCode,
      } = payload;
      if (status?.code && status?.code !== 200) {
        setErrMessage(status?.message);
      } else if (status === false) {
        setErrMessage(message);
      }
      if (showVerifiedCode && status === true) {
        setErrMessage('Lütfen telefonunuza gelen doğrulama kodunu giriniz.');
        startDate.current = Date.now();
        setTime(time * 1000);
        setRefcode(refCode);
        setReSendCode(true);
      }
      setOTP('');
      dispatch(
        setAddressFieldValueAction({
          addressType: addressForm?.addressType,
          key: 'otp',
          value: '',
        }),
      );
    });
  };

  const handleChange = (otp) => {
    if (otp?.match(/[^0-9]/g)) {
      setErr(() => (prevState = true));
      return false;
    }

    setOTP(otp);
    if (formType === 'addressForm') {
      dispatch(
        setAddressFieldValueAction({
          addressType: addressForm?.addressType,
          key: 'otp',
          value: otp,
        }),
      );
    }
  };

  useEffect(() => {
    if (
      formDetail?.type === 'register' ||
      formDetail?.type === 'phoneLogin'
    ) {
      const type =
        formDetail?.type === 'register'
          ? 'registerForm'
          : 'phoneForm';
      setFormType(type);
      setPhone(formDetail?.phone);
      setRefcode(formDetail?.refCode);
      setTime(formDetail?.time * 1000);
    }
    if (formDetail?.type === 'address') {
      setAddressForm(formDetail);
      setFormType('addressForm');
      setPhone(formDetail?.phone);
      setRefcode(formDetail?.refCode);
      setTime(formDetail?.time * 1000);
    }

    if (formDetail?.type === 'email') {
      setTime(formDetail?.time * 1000);
    }

    // if (formDetail && Object?.keys(formDetail)?.length === 0) {
    //   goToLogin(BASE_URL, CHECKOUT_BASE_URL);
    // }
  }, []);

  const sendReOtp = () => {
    setOTP('');
    dispatch(
      setAddressFieldValueAction({
        addressType: addressForm?.addressType,
        key: 'otp',
        value: '',
      })
    );
    if (formType === 'addressForm') {
      const recodeValue = reCodeCount + 1;
      setReCodeCount(recodeValue);
      if (addressForm?.actionType === 'edit') {
        updateCustomerAddress({
          shippingAddress:
            addressForm?.addressType === 'shippingAddress'
              ? shippingAddress
              : billingAddress,
          action: 'update',
          hidePopup: false,
          repeatCode: recodeValue.toString(),
        });
      } else if (addressForm?.actionType === 'new') {
        insertCustomerAddress({
          shippingAddress:
            addressForm?.addressType === 'shippingAddress'
              ? shippingAddress
              : billingAddress,
          action: 'insert',
          sameAddress: useShippingAddressAsBillingAddress,
          addressType: addressForm?.addressType,
          hidePopup: false,
          repeatCode: recodeValue.toString(),
        });
      }
    } else if (formType === 'registerForm') {
      const recodeValue = reCodeCount + 1;
      setReCodeCount(recodeValue);
      const requestData = {
        'g-recaptcha-response': formDetail['g-recaptcha-response'],
        password: formDetail?.password,
        phoneNumber: formDetail?.phoneNumber,
        email: formDetail?.email,
        genderId: formDetail?.genderId,
        hash: formDetail?.hash,
        version: formDetail?.version,
        repeatCode: recodeValue.toString(),
      };
      dispatch(registerAction(requestData)).then(({ payload }) => {
        const { showVerifiedCode, phone, refCode, time } = payload;
        if (showVerifiedCode === true) {
          setPhone(phone);
          setRefcode(refCode);
          setTime(time * 1000);
          reSendCode(true);
        }
      });
    } else if (formType === 'phoneForm') {
      dispatch(
        phoneLoginAction({ phoneNumber: formDetail?.phone })
      ).then(({ payload }) => {
        const { showVerifiedCode, phone, refCode, time } = payload;
        if (showVerifiedCode === true) {
          setPhone(phone);
          setRefcode(refCode);
          setTime(time * 1000);
        }
      });
    } else if (formDetail?.type === 'email') {
      APIs?.customerMailVerification({
        email: formDetail?.emailInfo,
      })
        .then((response) => {
          const resData = response.data
          if (resData.showVerifiedCode === true) {
            setTime(resData.time * 1000)
          }
        })
        .catch((err) => console.log(err));
    }
    setReSendCode(true);

    // kodu tekrar gönderince sürenin yenilenmemesinden dolayı eklendi
    if (timeReset) {
      startDate.current = Date.now();
      setTimeReset(false);
    }
  };

  const sendOTP = () => {
    if (OTP?.length < 5) {
      setErr(() => (prevState = true));
      setErrMessage(() => (prevState = true));
    }
    if (formType === 'addressForm') {
      if (addressForm?.actionType === 'edit') {
        updateCustomerAddress({
          shippingAddress:
            addressForm?.addressType === 'shippingAddress'
              ? shippingAddress
              : billingAddress,
          action: 'update',
          hidePopup: true,
        });
      } else if (addressForm?.actionType === 'new') {
        insertCustomerAddress({
          shippingAddress:
            addressForm?.addressType === 'shippingAddress'
              ? shippingAddress
              : billingAddress,
          action: 'insert',
          sameAddress: useShippingAddressAsBillingAddress,
          addressType: addressForm?.addressType,
          hidePopup: true,
        });
      }
    }
    if (formDetail?.type === 'register') {
      const requestData = {
        'g-recaptcha-response': formDetail['g-recaptcha-response'],
        password: formDetail?.password,
        phoneNumber: formDetail?.phoneNumber,
        email: formDetail?.email,
        genderId: formDetail?.genderId,
        hash: formDetail?.hash,
        version: formDetail?.version,
        code: OTP,
        is_otp_page: true,
      };
      dispatch(registerAction(requestData)).then(({ payload }) => {
        const { success, message } = payload;
        if (success == true) {
          navigate('/payment');
        } else {
          setErrMessage(message);
        }
      });
    }
    if (formType === 'phoneForm') {
      const request = {
        phoneNumber: formDetail?.phone,
        code: OTP,
      };

      dispatch(phoneLoginAction(request)).then(({ payload }) => {
        const { status, showPasswordScreen, shoppingCartItemCount } =
          payload;
        if (status.code === 200) {
          if (showPasswordScreen) {
            dispatch(isPhoneLoginAction(true));
            goToLogin(BASE_URL, CHECKOUT_BASE_URL);
          } else if (status.token) {
            if (shoppingCartItemCount > 0) {
              navigate('/payment');
            } else {
              navigate('/basket');
            }
          }
        } else {
          setErrMessage(status.message);
        }
      });
    }

    if (formDetail?.type === 'email') {
      APIs?.customerMailVerification({
        email: formDetail.emailInfo,
        code: OTP,
      })
        .then((response) => {
          if (response?.data?.success) {
            dispatch(hidePopupAction());

            // doğrulama yapıldığında global state'de bilgi güncelleniyor
            dispatch(setIsMailVerifiedAction(true));

            toast.dismiss();
            toast.error(ToastfySuccess({
              text: response.data.message,
              message: 'Keyifli alışverişler dileriz…',
            }), {
              autoClose: 3000,
              position: 'bottom-right',
              className: 'email-otp-toast',
            });
          } else {
            setErrMessage(response?.status?.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (OTP?.length > 5) {
      if (err === true) {
        setErr(() => (prevState = false));
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  }, [OTP]);

  return (
    <div className={formDetail?.type === 'email' ? 'otp-container email-otp-container' : 'otp-container'}>
      { isBackActive && (
      <span className="login-back-button otp-header__back-button" onClick={onBackButtonClick} data-test="checkout-otp-form-back-button">
        <BackButton />
        </span>
      )}

      <div className={`otp-content ${project}`}>
        { formDetail?.type === 'email' && (
          <div className="otp-content__head">
            <h4>{gettext('E-Posta Doğrulama')}</h4>
          </div>
        )}
        <div className="otp-content__title">
          { formDetail?.type === 'email'
            ? (
              <>
                {project === 'sneakerbox' ? gettext('otp-form-text-1') : gettext('Lütfen')}
                {' '}
                <strong>{formDetail?.emailInfo}</strong>
                {' '}
                {project !== 'sneakerbox' && (
                  <>
                    {gettext('adresine gelen')}
                    {' '}
                    <strong>{gettext('6 haneli')}</strong>
                    {' '}
                    {gettext('doğrulama kodunu girin.')}
                  </>
                )}
              </>
            )
            : gettext(`${phone}  numaralı telefonunuza gönderdiğimiz doğrulama
          kodunu girin. Ref:${refCode}`)}
        </div>
        <div className="otp-content__input-container">
          <div className={`otp-content__count-section ${project}`}>
            <div className="otp-content__timer-section--title">
              {gettext('Doğrulama Kodu')}
            </div>
            <div className="otp-content__timer-section--count">
              <span className="icon-area">
                <img
                  src={project === 'lumberjack' || project === 'lumberjack_it' || project === 'ninewest' || project === 'reebok' ? IconTimeBlack
                      : project === 'butigo' ? IconTimePurple
                      : IconTime}
                  alt="timer icon"
                />
              </span>
              <Countdown key={startDate?.current} date={startDate?.current + time} renderer={renderer} />
            </div>
          </div>
          <OTPInput
            value={OTP}
            onChange={handleChange}
            numInputs={6}
            separator={<span>&nbsp;</span>}
            inputStyle="otp-content-in--item"
            shouldAutoFocus
            focusStyle="active"
            containerStyle="otp-content-in"
            errorStyle="form-error"
            hasErrored={!!err}
            isInputNum
          />
          {errMessage && (
            <div className="error-message">
              {errMessage}
            </div>
          )}
          <div className="otp-content__re-send-title">
            <a onClick={sendReOtp} className={reSendCode ? 'disabled' : ''} data-test="checkout-otp-form-again-button">{gettext('Kodu Tekrar Gönder')}</a>
          </div>
        </div>
        <div className={`otp-content__button-container ${project}-otp-content__button-container`}>
          <button
            disabled={!!disabled}
            type="button"
            className="otp-content__btn"
            data-test="submit-otp-button-verify"
            onClick={sendOTP}
          >
            {gettext('Onayla')}
          </button>
          <button
            type="button"
            className="otp-content__btn btn--cancel"
            data-test="submit-otp-button-cancel"
            onClick={onBackButtonClick}
          >
            {gettext('Vazgeç')}
          </button>
        </div>
      </div>
    </div>
  );
};

OTPForm.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
  addressForm: PropTypes.object,
};

OTPForm.defaultValue = {
  addressForm: {},
  onBackButtonClick: () => {},
};

export default OTPForm;
