const data = {
  "’den alışveriş yaptığınız için teşekkür ederiz.": "Grazie per aver acquistato da",
  "’ı Aktif edin siz d": "Anche tu, attivati",
  "’ı Aktif edin siz de": "Anche tu, attivati",
  "’ni kabul ediyorum.": "Accetto che",
  "’ni okudum.": "L'ho letto.",
  "’si Kapıda Ödeme işlemi olduğu için hesabınıza nakit olarak aktarılabilir. Ücret iadenizi nakit olarak almak istiyorsanız lütfen": " Poiché si tratta di una procedura di pagamento alla porta, la somma può essere trasferita sul vostro conto in contanti. Se si desidera ricevere il rimborso in contanti, si prega di",
  "’ten alışveriş yaptığınız için teşekkür ederiz.": "Grazie per aver acquistato da",
  "“Fatura Adresim” “Teslimat Adresim” ile Aynı": "Il mio indirizzo di fatturazione coincide con quello di consegna",
  "/aydinlatma-metni": "/testo divulgativo",
  "/flo-cuzdan-kullanici-sozlesmesi": "/flo-portafoglio-accordo -con l'utente",
  "/page?url=sss-desktop&tab=cuzdan": "/page?url=sss-desktop&tab=cuzdan",
  "1- Siparişiniz kargo aracılığıyla ilgili mağazamıza teslim edilecektir.": "1- Il tuo ordine verrà consegnato al nostro negozio pertinente tramite carico.",
  "2- Mağazadan teslimat seçeneği sadece belirli mağazalarımızda geçerlidir.": "2- L'opzione di consegna in negozio è disponibile solo in alcuni negozi.",
  "3- Mağazadan teslimat seçeneğinde ödemelerinizi sadece kredi kartı ile yapabilirsiniz": "3- Per l'opzione di consegna in negozio, puoi effettuare i pagamenti solo con carta di credito. ",
  "4- Siparişiniz talep ettiğiniz mağazaya ulaştığında otomatik SMS ve e-mail gönderimi ile bilgi verilecektir.": "4- Quando il tuo ordine raggiungerà il punto vendita da te richiesto, verrai informato tramite SMS ed e-mail automatici.",
  "5- Mağazalarımızdan siparişinizi teslim alırken sipariş no, ad, soyad, TC kimlik no ve size gönderilen güvenlik kodunuzu mağaza personeline bildirmeniz gerekmektedir.": "5- Al momento del ritiro dell'ordine presso i nostri negozi, è necessario comunicare al personale del negozio il numero dell'ordine, il nome, il cognome, il numero di identificazione della Repubblica di Turchia e il codice di sicurezza inviato.",
  "6 haneli doğrulama kodunu almak için telefon numaranızı giriniz.": "Inserisci il tuo numero di telefono per ricevere il codice di verifica di 6 cifre.",
  "6- Mağazalarımıza teslim edilen siparişleriniz '3 iş günü içerisinde teslim alınmalıdır.'": "6- I tuoi ordini consegnati ai nostri negozi devono essere ricevuti entro 3 giorni lavorativi.",
  "7- Mağazalarımıza ulaşan siparişleriniz zamanında teslim alınmadığında iptal edilecektir.": "7- I tuoi ordini che arrivano presso i nostri negozi verranno annullati se non vengono ricevuti in tempo.",
  "8 karakter": "At least 8 characters",
  "8- Mağazalarımızdan teslimat hergün 10:00 - 20:00 saatleri arasında yapılmaktadır.": "8- La consegna dai nostri negozi viene effettuata tutti i giorni dalle 10:00 alle 20:00.",
  "Ad": "Nome",
  "AD SOYAD": "NOME COGNOME",
  "Ad ve Soyad": "Nome e cognome",
  "Adet": "Quantità:",
  "ADET:": "QUANTİTÀ:",
  "Adınız": "Nome e cognome",
  "Adres": "Indirizzo",
  "Adres Başlığı": "Titolo dell'indirizzo",
  "Adrese Teslimat": "Consegna a domicilio",
  "Adrese Teslim": "Consegna all'indirizzo",
  "Adres/Ödeme": " Indirizzo/Pagamento ",
  "Adresi Kaydet": " Salva indirizzo ",
  "adresine bir doğrulama e-postası gönderelim.": ".",
  "Ağustos": "Agosto",
  "aktarabilirsiniz.": "è possibile trasferire.",
  "Aktarmak İstediğiniz Tutar Giriniz": "Inserisci l'importo che desideri trasferire",
  "ALIŞVERİŞE BAŞLA": "INIZIA LO SHOPPING",
  "ALIŞVERİŞE DEVAM ET": "CONTINUA LO SHOPPING",
  "Alışveriş tutarınıza kargo firması tarafından talep edilen": "richiesto dall'azienda di trasporto al vostro importo di spesa",
  "Alt Limit:": "Limite inferiore:",
  "Apple bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Non è stato possibile verificare i dati Apple, riprovare.",
  "Apple ile Giriş Yap": "Accedi con Apple",
  "Apple ile Kayıt Ol": "Registrati con Apple",
  "Aralık": "Dicembre",
  "Aşağıdaki ürün / ürünlerin stoğu tükendiği için, sepetinizden çıkarılmıştır.": "I seguenti prodotti sono stati rimossi dal carrello perché esauriti.",
  "Ay": "Mese",
  "aydinlatma-metni-mobile": "chiarimento",
  "Aynı Gün Mağazadan Teslimat": "Consegna in negozio il giorno stesso",
  "Bakiye": "Saldo",
  "BAKİYE YÜKLE": "Carica il saldo",
  "Bakiye:": "Saldo:",
  "Başka Bir Kart İle Ödeme Yap": "Pagare con un'altra carta",
  "Başka Kredi Kartı İle Öde": "Pagare con un'altra carta di credito",
  "Beden": "Dimensioni",
  "Beden:": "Dimensioni:",
  "Beğendiğiniz ürünleri kolayca sepetinize ekleyebilir ve arkadaşınızla paylaşabilirsiniz.": "Puoi facilmente aggiungere i prodotti che ti piacciono al carrello e condividerli con il tuo amico.",
  "binlerce ürün ve model seni bekliyor.": "Migliaia di prodotti e modelli ti aspettano.",
  "Bir tutar giriniz.": "Inserire l'importo.",
  "Bu alanın doldurulması zorunludur.": "Questo campo è obbligatorio.",
  "Bu ekranı gördüğünüz için üzgünüz!": " Mi dispiace che tu abbia dovuto vedere questa schermata!",
  "Bu ürünler": "Questi prodotti",
  "Bu ürünün iadesini yalnızca Kargo ile gerçekleştirebilirsiniz.": "È possibile effettuare la restituzione di questo prodotto solo con spedizione.",
  "buraya": "qui",
  "büyük harf": "lettera maiuscola",
  "Cep Telefonu": "Telefono cellulare",
  "Cüzdan Detayları": "Dettagli del portafoglio",
  "Cüzdana yüklemek istediğiniz tutarı giriniz.": "Inserire l'importo che si desidera ricaricare il portafoglio.",
  "CVV": "CVV.",
  "değerinde": "Vale",
  "daha ürün ekleyin.": "aggiungere altri prodotti.",
  "DEMO": "DEMO",
  "DENEME": "TENTATIVO",
  "DEVAM ET": "CONTINUARE",
  "Devam Et": "Contınuare",
  "Devam etmek ister misiniz?": "Vuoi continuare?",
  "Diğer Adreslerim": "Altri indirizzi",
  "Düzenle": "Modifica",
  "Doğrulama Kodu": " Codice di Verifica ",
  "Doğrulama E-postası Gönder": "Inviare l'e-mail di verifica",
  "E-Posta Adresi": "Indirizzo e-mail",
  "E-Posta Adresiniz": "Indirizzo e-mail",
  "Ekim": "Ottobre",
  "eklenecektir.": "sarà aggiunto.",
  "En az": "Minimo",
  "En fazla": "Massimo",
  "En fazla 20 adet ürün ekleyebiliriz": "Possiamo aggiungere fino a 20 prodotti",
  "Erkek": "Ragazzo",
  "Eylül": "Settembre",
  "E-Posta Doğrulama": "Verifica e-mail",
  "Facebook bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "Non è stato possibile verificare le tue informazioni su Facebook, riprova.",
  "Facebook ile Giriş Yap": "Accedi con Facebook",
  "Facebook ile Kayıt Ol": "Registrati con Facebook",
  "Fatura Adresi": "Indirizzo di fatturazione",
  "Faturamı aynı adrese gönder": "Invia la mia fattura allo stesso indirizzo",
  "Filtrele": "Filtro",
  "FLO Cüzdan": "Portafoglio FLO ",
  "FLO Cüzdan ile Öde": "Pagare con il portafoglio FLO",
  "FLO Cüzdan ile Ödendi": " Pagato con il portafoglio FLO ",
  "Flo Cüzdan Kullanıcı Sözleşme": "Accordo con l'utente del portafoglio Flo",
  "Flo Cüzdan Kullanıcı Sözleşmesi": "Accordo con l'utente del portafoglio Flo",
  "FLO Cüzdan Oluştur": "Crea il portafoglio FLO",
  "FLO Cüzdana Yüklendi ve Ödendi": "Portafoglio FLO caricato e pagato",
  "FLO Cüzdanı Aktif Et ve Öde": "Attivare e pagare il portafoglio FLO",
  "Flo Cüzdanım": "Il mio portafoglio Flo",
  "FLO Cüzdanım": "IL MIO PORTAFOGLIO FLO",
  "FLO Cüzdanım’a Git": "Vai al mio portafoglio FLO",
  "FLO Cüzdanınız´a": "Al tuo portafoglio FLO",
  "FLO Cüzdanınızı aktif etmek için": "Per attivare il vostro portafoglio FLO",
  "FLO Mağazacılık ve Pazarlama A.Ş. Müşteri Aydınlatma Metni": "FLO Mağazacılık ve Pazarlama A.Ş.Testo di chiarimento per i clienti",
  "Flo Müşteri Aydınlatma Metni": "Testo di chiarimento del cliente Flo",
  "FLO Para": "Soldi FLO",
  "FLO Para Geri Kazan": "FLO Recuperare denaro",
  "Flo Para Kazan": "Guadagnare soldi Flo",
  "FLO Para Kazan": "Guadagnare soldi FLO",
  "FLO PARA KAZANDIN!": "HAI GUADAGNATO SOLDI FLO!",
  "FLO Para Kullan": "Utilizzare i soldi FLO",
  "FLO Paralarınız karta iade edilemez.": "Le tue soldi FLO non possono essere restituite alla carta.",
  "flo-cuzdan-kullanici-sozlesmesi-app": "flo-cuzdan-kullanici-sozlesmesi-app",
  "FLO Plus ile Hızlı Öde": "Pagare velocemente con FLO Plus",
  "Geçerli telefon numarası giriniz.": "Inserire il numero di telefono attuale.",
  "Geçerliliğini Kaybedecek FLO Paralarım": "Le mie soldi FLO che scadranno",
  "geçerlilik": "validità",
  "Geçerlilik süresini yitirecek olan FLO Paralar aşağıda aylık olarak listelenmektedir.": "Le soldi FLO che scadranno sono elencate di seguito su base mensile.",
  "Genel Toplam": "Totale generale",
  "Giriş": "Ingresso",
  "Giriş Yap": "Accesso",
  "GİRİŞ YAP": "ACCESSO",
  "GÖNDER": "INVIO",
  "Google bilgileriniz doğrulanamadı, lütfen tekrar deneyiniz.": "I tuoi dati Google non sono stati verificati, riprova.",
  "Google ile Giriş Yap": "Accedi con Google",
  "Google ile Kayıt Ol": "Registrati con Google",
  "Güncel": "Corrente",
  "Güvenli Alışveriş ve Ödeme": " Acquisti e Pagamenti Sicuri ",
  "Hata! Lütfen tekrar deneyiniz.": "Errore! Riprova.",
  "Haziran": "Giugno",
  "Hemen Aktif Et": "Attivo İmmediatamente",
  "Hemen Üye Ol": "Iscriviti ora",
  "Hesabınıza en fazla": "Potete ricaricare il vostro conto fino a",
  "Hesap Özetim": "Riepilogo del mio conto",
  "Hızlı Yükle": "Carico veloce",
  "İade Edilecek Kart": "Carta da rimborsare",
  "İade İşlemini Onayla": "Confermare la transazione di restituzione",
  "İade işlemlerinde fatura ibrazı zorunludur, faturasız iade kabul edilmemektedir.": " La presentazione della fattura è obbligatoria per i resi; i resi senza fattura non sono accettati.",
  "IBAN Numaranız": "Il vostro numero IBAN",
  "IBAN numaranız yukarıda vermiş olduğunuz ‘’Ad ve Soyad’’ bilgileriyle eşleşmelidir.": "Il vostro numero IBAN deve corrispondere alle informazioni `nome e cognome` che avete fornito sopra.",
  "İl": "Provincia",
  "İl Seçiniz": "Seleziona la provincia",
  "İlçe": "Distretto",
  "İlçe Seçiniz": "Seleziona la contea",
  "İndirim kodunuz varsa lütfen giriniz": "Se si dispone di un codice sconto, inserirlo",
  "İptal": "Annullare",
  "İptal Et": "Cancellare",
  "İşlem Gerçekleştiriliyor...": "Elaborazione...",
  "İndirimli": "Scontato",
  "Kadın": "Donne",
  "Kaldır": "Rimuovere",
  "Kalan Süreniz:": "Tempo rimanente:",
  "Kampanya": "Campagna",
  "Kampanya dönemlerinde yapılan sürpriz indirimlerden, stoklar ve bedenler tükenmeden ilk sizin haberiniz olsun.": "Sconti a sorpresa durante i periodi di campagna, siate i primi ad essere avvisati prima che le scorte si esauriscano.",
  "Kampanya Kodu": "Codice campagna / Coupon",
  "Kapıda kredi kartı ile ödeme kabul edilmemektedir": "Il pagamento con carta di credito alla porta non è accettato",
  "Kapıda Nakit Ödeme": "Contanti alla consegna",
  "Kapıda Ödeme": "Pagamento alla porta",
  "Kapıda ödeme seçeneğinde sadece nakit ödeme kabul edilmektedir.": "L'opzione di pagamento alla porta accetta solo pagamenti in contanti.",
  "Kapıda ödeme seçeneğiyle alınan ürünlerin iadesi mağazalarımızdan veya iade adresimize gönderilerek yapılabilir":
    "La restituzione dei prodotti acquistati con l'opzione di pagamento alla porta può essere effettuata presso i nostri negozi o inviandoli al nostro indirizzo di restituzione.",
  "kapıda ödeme ücreti": "pagamento alla porta",
  "Kargo Bedava": "Carico gratuito",
  "Kargo": "Carico",
  "Kargo Ücreti": "Costo di spedizione",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin <strong>Kargo Bedava</strong> olması gerekmektedir.": "Per non pagare le spese di spedizione, tutti gli articoli nel carrello devono essere <strong>liberi</strong>.",
  "Kargo bedava olması için": "Per la spedizione gratuita",
  "Kart Bilgileri": "Informazioni sulla carta",
  "Kart Numarası": "Numero di carta",
  "Karta Yüklenebilir FLO Para": "Carta caricabile FLO soldi",
  "Kartıma Geri Yükle": "Rimborso sulla carta",
  "Kartınıza en fazla": "La tua carta ha un massimo",
  "Kartınıza geri yükleme süresi bankalara bağlı olarak 2-10 iş günü arasında sürmektedir.": "Il tempo di riavvio della carta è compreso tra 2 e 10 giorni lavorativi, a seconda delle banche.",
  "Kartınızın arka yüzündeki son 3 rakamı yazınız.": "Scrivete le ultime 3 cifre sul retro della carta.",
  "Kasım": "Novembre",
  "KAYDET": "SALVARE",
  "KayTRYı Kart İle Devam Et": "Continua con la carta registrata",
  "kazanabilirdiniz.": "Potresti aver vinto.",
  "Kazandın!": "Hai vinto!",
  "Kazanmaya Başlayın!": "Inizia a vincere!",
  "Kendim": "Io stesso",
  "Kodu Tekrar Gönder": "Inviare nuovamente il codice",
  "Kolay İade Seçeneği": "Opzione di restituzione facile",
  "Kolay İade' Butonuyla hızlıca yapabilirsiniz.": " Puoi farlo rapidamente con il pulsante ‘Reso facile’.",
  "Kredi Kartı ile Yükleme Yap": "Carica con carta di credito",
  "Kredi Kartımı Sakla": "Salva la mia carta di credito",
  "Kopyala": "Copia",
  "Kopyalandı": "Copiato",
  "Kullan": "Utilizzo",
  "Kupon Kodunu Yazınız": "Inserisci il codice coupon",
  "Kurumsal Fatura İstiyorum": "Voglio una fattura aziendale",
  "Lütfen 3 karakter uzunluğunda bir değer giriniz": "Inserire un valore di 3 caratteri",
  "Lütfen bilgileri eksiksiz doldurunuz": "Si prega di compilare le informazioni complete",
  "Lütfen bu alanı doldurunuz": "Compilare questo campo",
  "Lütfen geçerli bir telefon girin.": "Inserire un telefono valido..",
  "Lütfen geçerli bir tutar giriniz.": "Inserire un importo valido.",
  "Lütfen IBAN Numaranızı Girin": "Inserire il numero IBAN",
  "Lütfen Kredi Kart Bilgilerinizi Yazın": "Inserire i dati della carta di credito",
  "Lütfen sayfayı yeniden yükleyin.": "Ricaricare la pagina.",
  "Lütfen T.C. kimlik numaranızı kontrol ediniz!": "Controllare il numero di identificazione!",
  "Lütfen vergi kimlik numaranızı kontrol ediniz!": "Controllare il codice fiscale!",
  "Mağaza Adresi": " Indirizzo del Negozio ",
  "Mağazadan": "Dal negozio",
  "Mağazadan nasıl teslim alırım?": " Come posso ritirare dal negozio?",
  "Mağazadan Teslimat Bilgileri": "Informazioni Sulla Consegna in Negozio",
  "Mağazadan Teslimat": " Consegna in Negozio ",
  "Mağazalardan nakit iade yapılmamaktadır": "Non sono previsti rimborsi in contanti dai negozi.",
  "Mağazasından": "Dal negozio",
  "Mahalle, cadde, sokak ve diğer bilgilerinizi giriniz.": "Inserite i dati relativi al quartiere, alla via e ad altri indirizzi.",
  "Mahalle": "Quartiere",
  "Mart": "Marzo",
  "Mayıs": "Maggio",
  "Mesafeli Satış Sözleşmesini": "Contratto di vendita a distanza",
  "Mobil Uygulamamız ile Sürpriz Avantajlar": "Vantaggi a sorpresa con la nostra applicazione mobile",
  "Mobil Uygulamasına özel yaptığımız etkinliklerden ilk sizin haberiniz olsun.": "Per avere notizie sulle attività che abbiamo in programma, è possibile utilizzare l'applicazione mobile.",
  "Nisan": "Aprile",
  "numaralı telefonuza gönderilen 6 haneli doğrulama kodunu giriniz.": "Immettere il codice di verifica a 6 cifre inviato al telefono numerato.",
  "Ocak": "Gennaio",
  "otp-form-text-1": " otp-form-text-",
  "Ödeme": "Pagamento",
  "ÖDEME": "PAGAMENTO",
  "ÖDEME ADIMINA GEÇ": "VAI AL PAGAMENTO",
  "Ödeme Seçenekleri": "Opzioni di pagamento",
  "Ödeme Yöntemi": " Metodo di Pagamento ",
  "Ödeme Bilgileri": " Informazioni sul Pagamento ",
  "ÖDEMEYİ TAMAMLA": "ORDINE COMPLETO",
  "Ödenecek Tutar": "Importo totale",
  "okudum onaylıyorum": "Ho letto e approvo",
  "olması gerekmektedir.": " deve essere disponibile.",
  "Ön Bilgilendirme Formunu": "Il modulo informativo preliminare",
  "Onay Kodu": "Codice di conferma",
  "ONAYLA": "APPROVARE",
  "Onayla": "Approvare",
  "Önceden Sepete Eklediklerim": " Aggiunti in precedenza al carrello",
  "rakam giriniz": "numero",
  "Sana en yakın mağazadan gel al.": " Vieni a ritirarlo nel negozio più vicino a te.",
  "Satın aldığınız ürünlerin iadelerini": "Restituzione dei prodotti acquistati",
  "Sayfa Bulunamadı": "Pagina non trovata",
  "Sepet": "Carrello",
  "Sepete Ekle": "Aggiungi al carrello",
  "SEPETİ ONAYLA": "CONFERMA CARRELLO",
  "Sepetim": "Il mio carrello",
  "Sepetinizde ürün bulunmamaktadır.": "Non ci sono articoli nel carrello.",
  "Sepetini Arkadaşınla Paylaş": " Condividi il tuo carrello con il tuo amico!",
  "Sepetinizdeki ürünü sepetten çıkardıktan sonra favorilerinize eklemek ister misiniz?": "Vuoi aggiungere questo articolo ai tuoi preferiti dopo averlo rimosso dal carrello?",
  "Sepetinizdeki Ürünün Stoğu Tükendi!": "Il prodotto nel carrello è esaurito!",
  "Sepette": "nel carrello ",
  "Siparişlerin ve güncel fırsatlarla ilgili e-postaları almaya devam etmek için": "Per continuare a ricevere e-mail sugli ordini e le offerte in corso:",
  "Şifre": "Password di accesso",
  "Şifremi Unuttum": "Ho dimenticato la mia password",
  "Şifreniz": "La tua password",
  "Sil": "Rimuovere",
  "Sil ve Favorilere Ekle": "Rimuovi e aggiungi ai preferiti",
  "Sipariş No:": "Numero d'ordine:",
  "Sipariş Numarası:": "Numero d'ordine:",
  "Sipariş Özeti": "Riepilogo dell'ordine",
  "Siparişime Git": "Vai a Il mio ordine",
  "SİPARİŞİNİZ BAŞARI İLE TAMAMLANMIŞTIR": "IL TUO ORDINE È STATO COMPLETATO CON SUCCESSO",
  "SİPARİŞLERİM": "I MIEI ORDINI",
  "Şirket Adı": "Nome dell'azienda",
  "Size Özel Kupon Fırsatları": "Opportunità di un buono speciale per te",
  "Son Kullanma Tarihi:": "Data di scadenza:",
  "SONRAKİ ADIMA DEVAM ET": "CONTİNUA İL PASSO SUCCESSİVO",
  "Soyad": "Cognome",
  "Soyadınız": "Il vostro cognome",
  "Sözleşme ve Formlar": "Contratto e Modulistica",
  "Standart Teslimat": "Consegna Standard",
  "Şubat": "Febbraio",
  "Tahmini Kargoya Teslimat:": "Consegna stimata della spedizione :",
  "Tahmini Kargoya Veriliş Tarihi:": "Data di spedizione stimata:",
  "Taksit": "Rateizzazione",
  "Taksit Seçenekleri": "Opzioni di pagamento",
  "Taksit seçenekleri kart bilgilerinizi girdikten sonra güncellenecektir.": "Le opzioni di pagamento rateale saranno aggiornate dopo l'inserimento dei dati della carta.",
  "TAMAM": "OK",
  "tarafından gönderilecektir.": "sarà inviato da.",
  "Tek Çekim": "Pagamento unico",
  "Tekrar SMS Gönder": "Inviare nuovamente l'SMS",
  "Telefon Numaranız": "Il vostro numero di telefono",
  "Telefon numaranızı kontrol ediniz.": "Il numero di telefono non è valido.",
  "telefon numarasına gönderilen 6 haneli kodu giriniz.": "Inserire il codice a 6 cifre inviato al numero di telefono.",
  "Temmuz": "luglio",
  "Teşekkürler": "Grazie",
  "Teslim Alacağım": "Riceverò",
  "teslim edilecektir": "sarà consegnato",
  "Teslimat Adresi": "Indirizzo di consegna",
  "Teslimat Bilgileri": "Informazioni sulla consegna",
  "Teslimat Seçenekleri": "Opzioni di consegna",
  "TEST": "TEST",
  "tıklayın.": "Clicca.",
  "Toplam": "Totale",
  "TL": "TRY",
  "TL ’den": " Da TRY ",
  "TL bakiye yükleyebilirsiniz.": "TRY è possibile ricaricare il saldo",
  "TL en fazla": " TRY massimo ",
  "TL hesabınıza iade edebilirsiniz.": " È possibile restituirlo al proprio conto TRY.",
  "TL iade edilebilir.": " TRY può essere restituito.",
  "TL kartınıza iade edebilirsiniz.": " Il TRY può essere restituito alla carta.",
  "TL’ye yükselmiştir.": " Aumentato a TRY.",
  "tr": "TRY ",
  "TR99 9999 9999 9999 9999 9999 99": "TR99 9999 9999 9999 9999 99",
  "TRY": " TRY ",
  "tutarında yükleme yapıldı ve ödeme işlemi tamamlandı.:": "L'importo è stato caricato e il processo di pagamento è stato completato.",
  "ÜCRETSİZ": " GRATUITO ",
  "Ücretsiz": " Gratuıto ",
  "Ücretsiz Kargo": " Spedizione gratuita ",
  "Ürün": " Articoli.",
  "ürün": " Articoli.",
  "Ürünler": "Prodotti",
  "ürün daha ekleyin Kargo Bedava olsun.": "Aggiungi altri prodotti e ottieni la spedizione gratuita.",
  "Ürün Var": " articoli nel carrello ",
  "Ürünlerinizi aşağıda adresi bulunan mağazamızdan teslim alabilirsiniz.": "Puoi ritirare i tuoi prodotti presso il nostro punto vendita all'indirizzo riportato di seguito.",
  "uyarınca işlenecektir.": " saranno elaborati in base a.",
  "ÜYE OL": " ISCRIVITI ",
  "HESAP OLUŞTUR": " CREARE UN ACCOUNT ",
  "Üye olmadan": " Senza un membro ",
  "ÜYE OLMADAN ALIŞVERİŞ": " ACQUİSTİ SENZA ACCOUNT ",
  "Üye Olmadan Devam Et": "Continuare senza account",
  "üyeleri kapıda nakit ödeme avantajından yararlanabilirler.": "i soci possono usufruire del pagamento in contanti alla porta.",
  "Üyeleri, hiç kullanmadığı ürünleri 14 gün içerisinde hızlı ve kolay bir işlemle iade edebilme avantajından yararlanırlar.": "I soci hanno il vantaggio di poter restituire i prodotti che non hanno mai utilizzato entro 14 giorni con una procedura semplice e veloce.",
  "Üyelerimize özel yaptığımız kupon etkinliklerinden faydalanın": " Approfittate delle attività di coupon preparate per i soci. ",
  "ÜYELİK AVANTAJLARI": "Vantaggi per i soci",
  "uyelik_sozlesmesi": "accordo _di adesione",
  "Uygula": " Applicare ",
  "Uyguladığınız kupon, sepetinizdeki indirim ile geçerli olmadığından sepet tutarınız": " L'importo del carrello, in quanto il coupon applicato non è valido con lo sconto presente nel carrello ",
  "VAZGEÇ": " ANNULLARE ",
  "Vazgeç": " Annullare ",
  "ve": "e",
  "ve üzeri ücretsiz kargo": "e oltre spedizione gratuita",
  "Vergi Dairesi": " Ufficio delle imposte ",
  "Vergi/ TC Kimlik No": " N. fiscale/identità ",
  "Vergi/ TC Kimlik No hatalı kontrol ediniz.": " Verificare che il codice fiscale/identificativo non sia corretto..",
  "veya": "o",
  "ya Giriş Yapın": " Accedi a ",
  "Yapmış olduğun alışverişten": "Mi dispiace per il suo acquisto",
  "Yeni Adres Ekle": " Aggiungi nuovo indirizzo ",
  "Yıl": " Anno ",
  "Yüklediğin Para": "Soldi caricati",
  "Yükleniyor": "Caricamento",
  "Tahmini Teslimat:": "Consegna stimata:",
  "Kargo ücreti ödememiz için sepetinizdeki tüm ürünlerin": " Tutti gli articoli nel carrello per il pagamento della spedizione ",
  "SEPETE GİT": " VAI AL CARRELLO ",
  "SİPARİŞİ TAMAMLA": " ORDINE COMPLETO ",
  "Siparişiniz hazırlandığında e-posta ile sizi bilgilendireceğiz.": "Ti avviseremo via email quando il tuo ordine sarà pronto.",
  "Siparişinizle ilgili gelişmeleri Siparişlerim sayfasından takip edebilirsiniz.": "Puoi seguire gli sviluppi del tuo ordine nella pagina I miei ordini.",
  "Lütfen geçerli bir eposta adresi yazın": " Inserire un indirizzo e-mail valido ",
  "KayTRYı kullanıcı bulunamadı": " Nessun utente registrato trovato ",
  "istenilen miktarda ürün bulunmamaktadır.": " non c'è prodotto nella quantità desiderata.",
  "Kredi / Banka Kartı": "Carta di credito/debito",
  "Lütfen geçerli bir numara giriniz.": "Inserisci un numero valido.",
  "Adres bilgisi 10 karakterden fazla olmalıdır.": "Le informazioni sull'indirizzo devono contenere più di 10 caratteri.",
  "Lütfen en az 5 karakter uzunluğunda bir değer giriniz.": "Inserire un valore di almeno 5 caratteri.",
  "Lütfen en az 10 karakter uzunluğunda bir değer giriniz.": "Inserire un valore di almeno 10 caratteri.",
  "E-Posta Adresinizi Doğrulayın": "Verifica il tuo indirizzo e-mail",
  "Lütfen kupon kodunu giriniz.": "Inserisci il codice coupon.",
  "1- Mağazadan teslimat seçeneğinde il ve ilçe seçimi yaptıktan sonra teslim alınabilecek mağazaları görüntüleyebilirsiniz.": "1 - Dopo aver selezionato la provincia e il distretto nell'opzione di consegna al negozio, è possibile visualizzare i negozi che possono essere ritirati.",
  "2- Siparişiniz teslim almak için hazırlandığında SMS ve e-posta ile bilgi paylaşılacaktır.": "2- Quando l'ordine viene preparato per la consegna, le informazioni vengono condivise via SMS e via e-mail.",
  "3- Siparişinizi mağazadan teslim alırken SMS ile gönderilen güvenlik kodunu mağaza çalışanı ile paylaşmanız gerekmektedir.": "3- Al momento del ritiro dell'ordine presso il negozio, è necessario condividere con il dipendente del negozio il codice di sicurezza inviato via SMS.",
  "4- Siparişiniz hazırlandıktan sonra 10 gün içerisinde mağazadan teslim alınmalıdır.": "4- L'ordine deve essere ritirato in negozio entro 10 giorni dalla preparazione.",
  "5- Mağazadan 10 gün içerisinde teslim alınmayan siparişin iade girişi yapılacaktır.": "5- Verrà effettuato il reso dell'ordine non ricevuto dal negozio entro 10 giorni.",
  "6- Siparişinizi mağazadan her gün 10:00 - 21:00 saatleri arasında teslim alabilirsiniz.": "6- È possibile ritirare l'ordine in negozio dalle 10:00 alle 21:00 tutti i giorni.",
  "7- Saat 19:00'a kadar verdiğiniz siparişinizi aynı gün 21:00'a kadar teslim alabilirsiniz.": "7- È possibile ricevere l'ordine effettuato dalle 19:00 alle 21:00 dello stesso giorno.",
  "Loyalty": "Lealtà",
  "Puanı hemen kullan.": "Riscatta i tuoi punti adesso.",
  "Lütfen en az 3 karakter uzunluğunda bir değer giriniz.": "Inserisci un valore lungo almeno 3 caratteri."
};

export default data;
